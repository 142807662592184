import ReactDOM from 'react-dom';
import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './contexts/Auth0Context';
import { apolloClient, auth0Config } from 'src/config';

import { ApolloProvider } from '@apollo/client';

// @TODO : to improve
const sessionStorageCache = {
  get: function (key) {
    return JSON.parse(sessionStorage.getItem('tokens'));
  },

  set: function (key, value) {
    sessionStorage.setItem('tokens', JSON.stringify(value));
  },

  remove: function (key) {
    sessionStorage.removeItem('tokens');
  },

  // Optional
  allKeys: function () {
    return Object.keys(sessionStorage);
  }
};

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />

          <Auth0Provider
            domain={auth0Config.domain}
            clientId={auth0Config.clientId}
            redirectUri={auth0Config.redirectUri}
            audience={auth0Config.audience}
            cache={sessionStorageCache}
          >
            <ApolloProvider client={apolloClient}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ApolloProvider>
          </Auth0Provider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
