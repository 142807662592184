import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  icon?: ReactNode;
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboard',
    icon: BackupTableTwoToneIcon,
    items: [
      {
        name: 'Product Catalogue',
        icon: BackupTableTwoToneIcon,
        link: 'product-catalogue'
        // items: [
        //   {
        //     name: 'Product 1',
        //     link: '/productcatalogue/1'
        //   },
        //   {
        //     name: 'Product 2',
        //     link: '/productcatalogue/2'
        //   }
        // ]
      },
      {
        name: 'Food Safety',
        icon: BackupTableTwoToneIcon,
        link: 'food-safety'
      },
      {
        name: 'Brand Manager',
        icon: BackupTableTwoToneIcon,
        link: 'brand-manager'
      },
      {
        name: 'Account Settings',
        icon: BackupTableTwoToneIcon,
        link: 'account-settings'
      }
    ]
  }
];

export default menuItems;
