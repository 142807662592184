import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import LogoArtisanMarket from '../Svg/LogoArtisanMarket';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: block;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <LogoArtisanMarket />
    </LogoWrapper>
  );
}

export default Logo;
