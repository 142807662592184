import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import get from 'lodash-es/get';

export const PUBLIC_URL_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_AUTH0_API_AUDIENCE;

export const auth0Config = {
  redirectUri: PUBLIC_URL_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_API_AUDIENCE, // JWT contain two dots !
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  cacheLocation: 'localstorage'
};

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_AUTH0_API_AUDIENCE}/pimcore-graphql-webservices/api`
});

const authLink = new ApolloLink((operation, forward) => {
  // @TODO : a ameliorer
  // Voir comment utiliser la lib auth0-spa-js pour récupérer les token stocké sur session ou localstorage
  const tokens = JSON.parse(sessionStorage.getItem('tokens'));
  const accessToken = get(tokens, 'body.access_token', null);
  const idToken = get(tokens, 'body.id_token', null);
  const tokenType = get(tokens, 'body.token_type', null);
  // console.log(tokens.body);
  operation.setContext({
    headers: {
      ...(accessToken && { authorization: `${tokenType} ${accessToken}` }),
      ...(idToken && { authentication: `${tokenType} ${idToken}` })
    }
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});
