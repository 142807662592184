import { gql } from '@apollo/client';
export default gql`
  query GetArtisan {
    getArtisan {
      firstName
      lastName
      county {
        ... on object_County {
          name
        }
      }
      id
      email
    }
  }
`;
