import { Box, styled } from '@mui/material';

import Logo from 'src/components/LogoSign';

const LogoWrapper = styled(Box)(
  () => `
    width: 350px
`
);

function AppInit() {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </Box>
  );
}

export default AppInit;
