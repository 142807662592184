import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from 'src/theme/ThemeProvider';
import AppInit from 'src/components/AppInit';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

function App() {
  const content = useRoutes(router);

  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      (async () => loginWithRedirect())();
    }
  }, [isAuthenticated, isLoading]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {!isLoading && isAuthenticated ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
