import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import Layout from 'src/layouts';

import statusRoutes from './status';
import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const DashboardBrandManager = Loader(
  lazy(() => import('src/content/dashboard/BrandManager'))
);
const DashboardFoodSafety = Loader(
  lazy(() => import('src/content/dashboard/FoodSafety'))
);
const DashboardAccountSettings = Loader(
  lazy(() => import('src/content/dashboard/AccountSettings'))
);
const DashboardProductCatalogue = Loader(
  lazy(() => import('src/content/dashboard/ProductCatalogue'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const router: RouteObject[] = [
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'status',
    children: statusRoutes
  },
  {
    path: '/',
    element: (
      <Authenticated>
        <Layout />
      </Authenticated>
    ),
    children: [
      {
        path: 'product-catalogue',
        element: <DashboardProductCatalogue />
      },
      {
        path: 'brand-manager',
        element: <DashboardBrandManager />
      },
      {
        path: 'food-safety',
        element: <DashboardFoodSafety />
      },
      {
        path: 'account-settings',
        element: <DashboardAccountSettings />
      }
    ]
  }
];

export default router;
