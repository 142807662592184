import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/models/user';
import capitalize from '../utils/capitalize';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import GetArtisan from 'src/graphql/queries/GetArtisan';
import get from 'lodash-es/get';
import { auth0Config } from '../config';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = InitializeAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const {
    isLoading,
    isAuthenticated,
    error: errorAuth0,
    user,
    loginWithRedirect,
    logout: logoutInitial
  } = useAuth0();

  const logout = () => logoutInitial({ returnTo: auth0Config.redirectUri });

  const {
    loading,
    data: dataArtisan,
    error: errorGetArtisan
  } = useQuery(GetArtisan, {
    skip: !isAuthenticated
  });

  useEffect(() => {
    (async (): Promise<void> => {
      if (!!errorGetArtisan) logout();
      try {
        if (isAuthenticated) {
          // await getArtisan();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: {
                id: get(dataArtisan, 'getArtisan.id'),
                jobtitle: 'Owner',
                avatar: user.picture,
                email: get(dataArtisan, 'getArtisan.email'),
                name: user.name,
                role: user.role,
                location: user.location,
                username: user.username,
                posts: user.posts,
                coverImg: user.coverImg,
                followers: user.followers,
                description: user.description,
                firstName: capitalize(get(dataArtisan, 'getArtisan.firstName')),
                lastName: capitalize(get(dataArtisan, 'getArtisan.lastName')),
                county: get(dataArtisan, 'getArtisan.county.name')
              }
            }
          });
        } else {
          if (!isLoading) {
            await loginWithRedirect();
          }
        }
      } catch (err) {
        console.log(err);
        if (!isLoading) {
          await loginWithRedirect();
        }
      }
    })();
  }, [isAuthenticated, isLoading, dataArtisan]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
